const animatedHeadline = () => {
	const takeNext = (word) => {
		return word.nextElementSibling
			? word.nextElementSibling
			: word.parentElement.children[0]
	}

	const switchWord = (oldWord, newWord) => {
		oldWord.classList.remove('is-visible')
		oldWord.classList.add('is-hidden')
		newWord.classList.remove('is-hidden')
		newWord.classList.add('is-visible')
	}

	const hideWord = (word) => {
		var nextWord = takeNext(word)

		word.parentElement.classList.remove('is-loading')
		switchWord(word, nextWord)
		setTimeout(() => {
			hideWord(nextWord)
		}, 1900)

		setTimeout(() => {
			word.parentElement.classList.add('is-loading')
		}, 400)
	}

	const animateHeadline = (headline) => {
		setTimeout(() => {
			headline.querySelector('.words-wrapper').classList.add('is-loading')
		}, 400)

		// trigger animation
		setTimeout(() => {
			hideWord(headline.querySelector('.is-visible'))
		}, 1900)
	}

	const initHeadline = () => {
		const theHeadline = document.querySelector('.headline')
		animateHeadline(theHeadline)
	}

	return initHeadline()
}

export default animatedHeadline
