const navigation = (menuItems) => {
	const openBtn = document.querySelector('.burger')
	const header = document.querySelector('.header')
	const closeBtn = document.querySelector('.icon-close')
	const allBtns = [openBtn, closeBtn, ...menuItems]

	allBtns.forEach((item) =>
		item.addEventListener('click', (e) => {
			header.classList.toggle('open')
		})
	)
}

export default navigation
