// Serialize the form data into a query string
const serialize = function (form) {
	// Setup our serialized data
	let serialized = ''

	// Loop through each field in the form
	for (i = 0; i < form.elements.length; i++) {
		var field = form.elements[i]

		// Don't serialize fields without a name, submits, buttons, file and reset inputs, and disabled fields
		if (
			!field.name ||
			field.disabled ||
			field.type === 'file' ||
			field.type === 'reset' ||
			field.type === 'submit' ||
			field.type === 'button'
		)
			continue

		// Convert field data to a query string
		if (
			(field.type !== 'checkbox' && field.type !== 'radio') ||
			field.checked
		) {
			serialized +=
				'&' +
				encodeURIComponent(field.name) +
				'=' +
				encodeURIComponent(field.value)
		}
	}

	return serialized
}

const submitMailChimpForm = function (form) {
	// Get the Submit URL
	var url = form.getAttribute('action')
	url = url.replace('/post?u=', '/post-json?u=')

	if (!form.classList.contains('the-resource-form')) {
		url += serialize(form) + '&c=displayMailChimpStatus'
	}

	//Grabbing some elements for styling the message
	const mcStatus = form.querySelector('.mc-status')
	const button = form.querySelector('.mc-button-container')

	// Create script with url and callback (if specified)
	var script = window.document.createElement('script')
	script.src = url

	// Insert script tag into the DOM (append to <head>)
	var ref = window.document.getElementsByTagName('script')[0]
	ref.parentNode.insertBefore(script, ref)

	if (form.classList.contains('the-resource-form')) {
		form.nextElementSibling.click()
		form.nextElementSibling.nextElementSibling.innerHTML = `Thanks and enjoy!`
		form.style.display = 'none'
		console.log(form)
	}

	if (!form.classList.contains('the-resource-form')) {
		// Display the form status
		window.displayMailChimpStatus = function (data) {
			if (!data.result || !data.msg) return

			// Update our status message
			mcStatus.innerHTML = data.msg

			// If error, add error class
			if (data.result === 'error') {
				mcStatus.classList.remove('success-message')
				mcStatus.classList.add('error-message')
				return
			}

			// Otherwise, add success class
			mcStatus.classList.remove('error-message')
			mcStatus.classList.add('success-message')

			if (data.result === 'success') {
				button.style.display = 'none'
			}
		}
	}

	// After the script is loaded (and executed), remove it
	script.onload = function () {
		this.remove()
	}
}

const forms = () => {
	// Check all fields on submit
	document.addEventListener(
		'submit',
		function (event) {
			// Prevent form from submitting
			event.preventDefault()
			// Otherwise, let the form submit normally
			// You could also bolt in an Ajax form submit process here
			submitMailChimpForm(event.target)
		},
		false
	)
}

export default forms
