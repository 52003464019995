import Swup from 'swup'
import SwupScrollPlugin from '@swup/scroll-plugin'
import navigation from './navigation'
import initHomePage from './home-page/index.js'
import initCaseStudy from './case-studies.js'
import aboutBio from './about'
import forms from './forms'
import 'lazysizes'

const menuItems = [...document.querySelectorAll('.header .menu-item')]

const changeMenuItems = () => {
	menuItems.forEach((item) => {
		// 1. remove active class from all links
		item.classList.remove('current-menu-item')
		// 2. go through all links and add active class if href == current URL
		if (window.location.href == item.getAttribute('href')) {
			item.classList.add('current-menu-item')
		}
	})
}

const initPayload = () => {
	initHomePage()
	initCaseStudy()
	aboutBio()
	forms()
}

const swupOptions = {
	plugins: [new SwupScrollPlugin({ doScrollingRightAway: true })],
	animateHistoryBrowsing: true,
}

const run = () => {
	const swup = new Swup(swupOptions)
	initPayload()
	navigation(menuItems)
}

document.addEventListener('swup:contentReplaced', () => {
	initPayload()
	changeMenuItems()
	gtag('config', 'UA-124749352-1', {
		page_title: document.title,
		page_path: window.location.pathname,
	})
})

if (
	document.readyState === 'complete' ||
	(document.readyState !== 'loading' && !document.documentElement.doScroll)
) {
	run()
} else {
	document.addEventListener('DOMContentLoaded', run)
}
