const aboutBio = () => {
	if (document.querySelector('.the-team') !== null) {
		const people = document.querySelectorAll(
			'.the-team__wrapper .the-person'
		)

		people.forEach((person) => {
			person.addEventListener('click', () =>
				person.classList.toggle('reading')
			)
		})
	}
}

export default aboutBio
