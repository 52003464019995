import animatedHeadline from './animated-headline.js'
// import forms from './forms'

const initHomePage = () => {
	if (document.querySelector('.home-hero') !== null) {
		animatedHeadline()
		// forms()
	}
}

export default initHomePage
